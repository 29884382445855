import React, { useState, useEffect } from 'react';
import { User, MessageSquare } from 'lucide-react';
import { contactsApi } from '../../service/api/private/ContactsApi';
import { ContactDto, RequestDto, UserLinksDto } from '../../dto/Contact';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { useLocation, useNavigate } from 'react-router-dom';

const Network: React.FC = () => {
    const [showDeleteContactConfirmation, setShowDeleteContactConfirmation] = useState(false);
    const [showDeleteSavedConfirmation, setShowDeleteSavedConfirmation] = useState(false);
    const [showDeleteRequestConfirmation, setShowDeleteRequestConfirmation] = useState(false);
    const [showDeleteReceivedRequestConfirmation, setShowDeleteReceivedRequestConfirmation] = useState(false);

    const [contactToDelete, setContactToDelete] = useState<string | null>(null);
    const [userlinks, setUserlinks] = useState<UserLinksDto>();
    const [contactsRequestSent, setContactsRequestSent] = useState<RequestDto[]>();
    const [contactsRequestReceived, setContactsRequestReceived] = useState<RequestDto[]>();
    const [currentView, setCurrentView] = useState<'CONTACTS' | 'FAVORITE' | 'REQUESTED' | 'RECEIVED'>('CONTACTS');

    const isDarkMode = false;

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        contactsApi.getCurrentUserLinks()
            .then((result) => setUserlinks(result))
            .catch((error) => console.error(error));

        contactsApi.getRequestsSent()
            .then((result) => setContactsRequestSent(result.filter(r => !r.accepted)))
            .catch((error) => console.error(error));

        contactsApi.getRequestsReceived()
            .then((result) => setContactsRequestReceived(result.filter(r => !r.accepted)))
            .catch((error) => console.error(error));

        if (location.state && location.state.view) {
            setCurrentView(location.state.view)
        }
    }, [location]);

    const handleAcceptRequest = async (request: RequestDto) => {
        await contactsApi.acceptContactRequest(request.requesterId);

        userlinks?.contacts.push({
            id: request.requesterId,
            firstName: request.firstName,
            lastName: request.lastName,
            address: request.address,
            contacted: false
        })
        setUserlinks(userlinks);

        setContactsRequestReceived(contactsRequestReceived?.filter(c => c !== request));
    };

    const handleDeleteContact = (id: string) => {
        setContactToDelete(id);
        setShowDeleteContactConfirmation(true);
    };

    const confirmDeleteContact = async (id: string) => {
        await contactsApi.deleteContact(id);
        userlinks!.contacts = userlinks!.contacts.filter(c => c.id !== id)
        setUserlinks(userlinks);
        setShowDeleteContactConfirmation(false);
    };

    const handleDeleteSaved = (id: string) => {
        setContactToDelete(id);
        setShowDeleteSavedConfirmation(true);
    };

    const confirmDeleteSaved = async (id: string) => {
        await contactsApi.deleteFavorite(id);
        setUserlinks({ ...userlinks!, favoriteUsers: userlinks!.favoriteUsers.filter(c => c.id !== id) });
        setShowDeleteSavedConfirmation(false);
    };

    const handleInviteContact = async (contact: ContactDto) => {
        await contactsApi.newContactRequest(contact.id);
        console.log(contact, userlinks?.favoriteUsers)
        setUserlinks({ ...userlinks!, favoriteUsers: userlinks!.favoriteUsers.filter(c => c.id !== contact.id) });

        contactsRequestSent?.push({
            id: '',
            accepted: false,
            firstName: contact.firstName,
            lastName: contact.lastName,
            receiverId: contact.id,
            requesterId: '',
            address: contact.address,
            requestDate: new Date()
        })
        setContactsRequestSent(contactsRequestSent);
    };

    const handleDeleteRequest = (id: string) => {
        setContactToDelete(id);
        setShowDeleteRequestConfirmation(true);
    };

    const confirmDeleteRequest = async (id: string) => {
        await contactsApi.deleteRequest(id);
        setContactsRequestSent(contactsRequestSent!.filter(c => c.receiverId !== id));
        setShowDeleteRequestConfirmation(false);
    };

    const handleDeleteReceivedRequest = (id: string) => {
        setContactToDelete(id);
        setShowDeleteReceivedRequestConfirmation(true);
    };

    const confirmDeleteReceivedRequest = async (id: string) => {
        await contactsApi.rejectRequest(id);
        console.log(id, contactsRequestReceived)
        setContactsRequestReceived(contactsRequestReceived!.filter(c => c.requesterId !== id));
        setShowDeleteReceivedRequestConfirmation(false);
    };

    const handleChangeView = async (view: 'CONTACTS' | 'FAVORITE' | 'REQUESTED' | 'RECEIVED') => {
        setCurrentView(view);
    }

    return (
        <>

            {/* Content area */}
            <div className="flex-1 overflow-auto">
                <div className="container mx-auto px-4 py-8">
                    <div className="flex space-x-1 md:space-x-6 mb-6">
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'CONTACTS' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('CONTACTS')}>
                            Mes contacts
                        </button>
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'FAVORITE' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('FAVORITE')}>
                            Profils sauvegardés
                        </button>
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'RECEIVED' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('RECEIVED')}>
                            Invitations reçues
                        </button>
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'REQUESTED' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('REQUESTED')}>
                            Invitations envoyées
                        </button>
                    </div>

                    {/* Contact cards */}
                    <div className="space-y-4">
                        {currentView === 'CONTACTS' && !userlinks &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'CONTACTS' && userlinks && userlinks.contacts.map((contact) => (
                            <div key={contact.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {contact.image ?
                                        <img src={contact.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {contact.firstName + " " + contact.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{contact.address?.label}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        className={`px-4 py-1 ${isDarkMode ? 'bg-indigo-700' : 'bg-indigo-600'} text-white rounded-full text-sm font-medium hover:bg-indigo-700 relative`}
                                        onClick={() => navigate('/dashboard/messages', { state: { contact } })}
                                    >
                                        <MessageSquare size={16} className="mr-1 inline-block" />
                                        Message
                                    </button>
                                    <button className={`px-4 py-1 border ${isDarkMode ? 'border-red-700 text-red-500' : 'border-red-500 text-red-500'} rounded-full text-sm font-medium hover:bg-red-100`}
                                        onClick={() => handleDeleteContact(contact.id)}>
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* saved contact cards */}
                    <div className="space-y-4">
                        {currentView === 'FAVORITE' && !userlinks &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'FAVORITE' && userlinks && userlinks.favoriteUsers.map((contact) => (
                            <div key={contact.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {contact.image ?
                                        <img src={contact.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {contact.firstName + " " + contact.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{contact.address?.label}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        className={`px-4 py-1 ${isDarkMode ? 'bg-indigo-700' : 'bg-indigo-600'} text-white rounded-full text-sm font-medium hover:bg-indigo-700 relative`}
                                        onClick={() => handleInviteContact(contact)}
                                    >
                                        Inviter
                                    </button>
                                    <button className={`px-4 py-1 border ${isDarkMode ? 'border-red-700 text-red-500' : 'border-red-500 text-red-500'} rounded-full text-sm font-medium hover:bg-red-100`}
                                        onClick={() => handleDeleteSaved(contact.id)}>
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* received requests cards */}
                    <div className="space-y-4">
                        {currentView === 'RECEIVED' && !contactsRequestReceived &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'RECEIVED' && contactsRequestReceived && contactsRequestReceived.map((contact) => (
                            <div key={contact.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {contact.image ?
                                        <img src={contact.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {contact.firstName + " " + contact.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{contact.address?.label}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        className={`px-4 py-1 ${isDarkMode ? 'bg-indigo-700' : 'bg-indigo-600'} text-white rounded-full text-sm font-medium hover:bg-indigo-700 relative`}
                                        onClick={() => handleAcceptRequest(contact)}
                                    >
                                        Accepter
                                    </button>
                                    <button className={`px-4 py-1 border ${isDarkMode ? 'border-red-700 text-red-500' : 'border-red-500 text-red-500'} rounded-full text-sm font-medium hover:bg-red-100`}
                                        onClick={() => handleDeleteReceivedRequest(contact.requesterId)}>
                                        Refuser
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* contact requested cards */}
                    <div className="space-y-4">
                        {currentView === 'REQUESTED' && !contactsRequestSent &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'REQUESTED' && contactsRequestSent && contactsRequestSent.map((contact) => (
                            <div key={contact.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {contact.image ?
                                        <img src={contact.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {contact.firstName + " " + contact.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{contact.address?.label}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button className={`px-4 py-1 border ${isDarkMode ? 'border-red-700 text-red-500' : 'border-red-500 text-red-500'} rounded-full text-sm font-medium hover:bg-red-100`}
                                        onClick={() => handleDeleteRequest(contact.receiverId)}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Delete confirmation modal */}
            {showDeleteContactConfirmation && (
                <ConfirmationPopup type='ERROR'
                    title='Confirmer la suppression'
                    text='Êtes-vous sûr de vouloir supprimer ce contact ?'
                    handleCancel={() => setShowDeleteContactConfirmation(false)}
                    handleConfimrmation={() => confirmDeleteContact(contactToDelete!)} />
            )}

            {showDeleteSavedConfirmation && (
                <ConfirmationPopup type='ERROR'
                    title='Confirmer la suppression'
                    text='Êtes-vous sûr de vouloir supprimer ce profil sauvegardé ?'
                    handleCancel={() => setShowDeleteSavedConfirmation(false)}
                    handleConfimrmation={() => confirmDeleteSaved(contactToDelete!)} />
            )}

            {showDeleteReceivedRequestConfirmation && (
                <ConfirmationPopup type='ERROR'
                    title='Confirmer la suppression'
                    text='Êtes-vous sûr de refuser cette invitation ?'
                    handleCancel={() => setShowDeleteReceivedRequestConfirmation(false)}
                    handleConfimrmation={() => confirmDeleteReceivedRequest(contactToDelete!)} />
            )}

            {showDeleteRequestConfirmation && (
                <ConfirmationPopup type='ERROR'
                    title='Confirmer la suppression'
                    text='Êtes-vous sûr de vouloir supprimer cette invitation ?'
                    handleCancel={() => setShowDeleteRequestConfirmation(false)}
                    handleConfimrmation={() => confirmDeleteRequest(contactToDelete!)} />
            )}
        </>

    );
};

export default Network;