import { AxiosResponse } from "axios";
import { PublicApi } from "./PublicApi";
import { SignupDto } from "../../../dto/Signup";
import { ConfirmDto } from "../../../dto/Confirm";
import { ResetPasswordDto } from "../../../dto/ResetPassword";

class AuthenticationApi extends PublicApi {

    public async login(email: string, password: string) {

        const data = { email, password };
        const response: AxiosResponse = await this.post(`/auth/login`, data);

        return response.data;
    }

    public async loginGoogle(accessToken: string) {

        const data = { accessToken };
        const response: AxiosResponse = await this.post(`/auth/login/google`, data);

        return response.data;
    }

    public async refreshToken(refreshToken: string) {
        const response: AxiosResponse = await this.post(`/auth/refreshToken`, { refreshToken });

        return response.data;
    }

    public async signup(signup: SignupDto) {
        const response: AxiosResponse = await this.post(`/auth/signup`, signup);
    }

    public async confirm(confirm: ConfirmDto) {
        const response: AxiosResponse = await this.post(`/auth/confirm`, confirm);
    }

    public async sendMailresetPassword(email: string) {
        const response: AxiosResponse = await this.post(`/public/mail/resetPassword`, {
            templateId: 'reset_password',
            context: {
                email
            },
            to: email,
        });
    }

    public async resetPassword(resetPassword: ResetPasswordDto) {
        const response: AxiosResponse = await this.post(`/auth/resetPassword`, resetPassword);
    }
}

export const authenticationApi = new AuthenticationApi();