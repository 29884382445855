import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { MatchingCriteriasDto, UserDto, UserSettingsDto } from "../../../dto/User";

class UserApi extends PrivateApi {

    public async getCurrentUser() {
        const path = "/users/{id}";
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }


    public async setUserSettings(userSettings: UserSettingsDto) {

        const path = "/users/{id}/settings";
        const response: AxiosResponse = await super.put(path, userSettings);

        return response.data;
    }

    public async setMatchingCriterias(matchingCriterias: MatchingCriteriasDto) {
        const path = `/users/{id}/matchingcriterias`;
        const response: AxiosResponse = await super.put(path, matchingCriterias);

        return response.data;
    }

    public async updateUser(user: UserDto) {
        const path = `/users/{id}`;
        const response: AxiosResponse = await super.put(path, user);

        return response.data;
    }

    public async connectUser() {

        const path = `/users/{id}/online`;
        const response: AxiosResponse = await super.post(path, {});

        return response.data;
    }

    public async disconnectUser() {

        const path = `/users/{id}/offline`;
        const response: AxiosResponse = await super.post(path, {});

        return response.data;
    }
}

export const userApi = new UserApi();