import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { X, ArrowUp, ArrowDown } from "lucide-react";

export type MultiSelectItem = {
    id: string;
    value: string;
};

type MultiSelectProps = {
    items: MultiSelectItem[];
    values: MultiSelectItem[];
    placeholder: string;
    handleSetValue: (value: MultiSelectItem[]) => void;
    className?: string;
};

const SelectedItemsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const SelectIcon = styled.span`
  display: "inline-block";
  width: 20px;
`;

const DropDown = styled.ul<{
    opened: boolean;
    raised?: boolean;
    wrapperClientHeight: number;
}>`
  z-index:1;
  position: absolute;
  background-color: white;
  width: 100%;
  top: ${({ wrapperClientHeight }) => wrapperClientHeight}px;
  left: 0px;
  height: "inherit";

  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  box-shadow: ${(props) =>
        props.raised ? "6px 5px 22px -8px rgba(0,0,0,0.76)" : "none"};
  border-radius: 6px;
  padding: 6px;
  transition: all 0.2s linear 0.1s;
  & > li {
    list-style: none;
    padding: 8px;

    &:hover {
      background-color: papayawhip;
    }
  }
`;

export const MultiSelect: React.FC<MultiSelectProps> = ({
    items,
    values,
    placeholder = "Select an Item",
    handleSetValue,
    className
}) => {
    const [selectedItems, setSelectedItems] = useState<MultiSelectItem[]>(values);
    const [opened, setIsOpened] = useState(false);
    const wrapperRef = useRef(null);
    const [wrapperClientHeight, setWrapperClientHeight] = useState(40);
    const [customValue, setCustomValue] = useState('');

    const onClickWrapper = () => {
        setIsOpened(!opened);
    };

    const onClickDeleteItem = (id: string) => {
        const newSelectedItems = selectedItems.filter((item) => item.id !== id);
        setSelectedItems(newSelectedItems);
        handleSetValue(newSelectedItems)
    };

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            if (selectedItems.length > 0) {
                const newwrapperClientHeight =
                    wrapperRef && wrapperRef.current
                        ? (wrapperRef.current as any).clientHeight
                        : 25;

                setWrapperClientHeight(newwrapperClientHeight);
            } else {
                setWrapperClientHeight(40);
            }
        }
    }, [selectedItems]);

    useEffect(() => {
        if (selectedItems.length === items.length) {
            setIsOpened(false);
        }
    }, [selectedItems, items]);

    const onDropDownClicked = (newItem: MultiSelectItem) => {
        const newSelectedItems = [...selectedItems, newItem];
        setSelectedItems(newSelectedItems);
        handleSetValue(newSelectedItems)
    };

    const filteredItems = items.filter(
        (item) => selectedItems.findIndex((sel) => sel.id === item.id) === -1
    );
    return (
        <div className={className} onClick={onClickWrapper} ref={wrapperRef}>
            <SelectedItemsContainer>
                {selectedItems.map(({ id, value }) => (
                    <span className="bg-[#333697]/10 text-[#333697] px-2 py-1 rounded-full text-xs flex flex-wrap gap-2" key={id} onClick={(e) => e.stopPropagation()}>
                        <span>{value}</span>
                        <X size="15" color="gray" onClick={(e) => {
                            e.stopPropagation();
                            onClickDeleteItem(id);
                        }} />
                    </span>
                ))}
                <input className="bg-[#333697]/10 text-[#333697] px-2 py-1 rounded-full text-xs flex flex-wrap gap-2"
                    placeholder={placeholder}
                    value={customValue}
                    onChange={(e) => setCustomValue(e.target.value)}
                    onBlur={(e) => {
                        e.stopPropagation();
                        if (customValue) {
                            onDropDownClicked({ id: customValue, value: customValue });
                            setCustomValue('');
                        }
                    }}
                    onKeyPress={(e) => {
                        if (customValue && e.key === "Enter") {
                            onDropDownClicked({ id: customValue, value: customValue });
                            setCustomValue('');
                        }
                    }}
                />
            </SelectedItemsContainer>
            <SelectIcon>
                {opened ? <ArrowUp /> : <ArrowDown />}
            </SelectIcon>
            {filteredItems.length > 0 && (
                <DropDown
                    opened={opened}
                    raised={true}
                    wrapperClientHeight={wrapperClientHeight}
                >
                    {filteredItems.map(({ id, value }) => (
                        <li
                            key={id}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDropDownClicked({ id, value });
                            }}
                        >
                            {" "}
                            {value}
                        </li>
                    ))}
                </DropDown>
            )}
        </div>
    );
};
