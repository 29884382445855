import { AutosuggestOutput } from "../components/Autosuggest";

const jobs = [

    "Directeur des achats / Responsable achats",
    "Acheteur",
    "Assistant achats",

    "Responsable administratif",
    "Technicien de gestion administrative",
    "Agent administratif",

    "Assistant de direction générale",
    "Secrétaire de direction",
    "Secrétaire",

    "Directeur commercial",
    "Directeur des ventes",
    "Merchandiseur / Category manager",
    "Responsable export",
    "Responsable régional des ventes",
    "Responsable d’une équipe de vente",
    "Responsable d’une agence commerciale",
    "Ingénieur commercial ou d’affaires",
    "Technico-commercial",
    "Vendeur / Conseiller de vente",
    "Commercial sédentaire / télévendeur",
    "Assistant commercial / Assistant export",

    "Directeur de la communication",
    "Chargé de communication / Assistant communication",
    "Documentaliste",

    "Président-directeur général / Chef d’entreprise",
    "Directeur général adjoint / Directeur d’une division",
    "Directeur général",

    "Outilleur / Rectifieur",
    "Responsable du service entretien et maintenance",
    "Chef d’équipe entretien ou maintenance",

    "Directeur administratif et financier",
    "Directeur financier",
    "Responsable administratif et financier",
    "Responsable trésorerie",
    "Contrôleur de gestion / Auditeur interne",
    "Responsable du service comptabilité / Directeur comptable",
    "Comptable",
    "Assistant contrôle de gestion",
    "Aide-comptable / Assistant comptable",

    "Chef de projet web / multimédia",
    "Responsable sécurité informatique",
    "Administrateur systèmes et / ou réseaux",
    "Directeur des systèmes d’information / directeur informatique",
    "Responsable des systèmes d’information",
    "Chef de projet informatique",
    "Ingénieur d’études / Ingénieur de développement informatique",
    "Analyste fonctionnel",
    "Analyste programmeur / Développeur",
    "Responsable d’exploitation / Responsable de production",
    "Responsable d’application",
    "Gestionnaire de parc informatique",
    "Ingénieur systèmes et/ou réseaux",
    "Architecte systèmes d’information",
    "Administrateur de base de données",
    "Technicien micro-informatique",
    "Technicien support clients",

    "Directeur juridique / Responsable juridique",
    "Juriste d’entreprise",
    "Assistant juridique",

    "Réceptionnaire / Expéditionnaire",
    "Directeur logistique / Directeur supply chain",
    "Logisticien / Responsable d’exploitation",
    "Responsable de magasin / d’entrepôt",
    "Approvisionneur",
    "Assistant logistique / Agent d’exploitation",
    "Magasinier préparateur de commandes",
    "Manutentionnaire-cariste",
    "Chauffeur-livreur",
    "Chauffeur poids lourd",

    "Graphiste / Infographiste",
    "Directeur marketing / Responsable marketing",
    "Chef de produits",
    "Assistant marketing / Assistant chef de produits",

    "Directeur industriel",
    "Responsable méthodes / Ingénieur méthodes",
    "Technicien méthodes",

    "Directeur d’usine",
    "Directeur technique",
    "Directeur de production / Responsable de production",
    "Responsable planning / ordonnancement / lancement",
    "Agent de planning et ordonnancement",
    "Chef d’atelier / Responsable de fabrication",
    "Chef d’équipe d’ouvriers",
    "Technicien d’atelier",
    "Ouvrier hautement qualifié (OHQ)",
    "Ouvrier qualifié (P1 à P4)",
    "Ouvrier spécialisé",
    "Opérateur de conditionnement",
    "Conducteur de machine",

    "Animateur sécurité / Coordinateur sécurité",
    "Directeur qualité",
    "Responsable hygiène sécurité environnement (HSE)",
    "Responsable qualité / Ingénieur qualité",
    "Technicien qualité",
    "Agent contrôle qualité",

    "Directeur recherche et développement",
    "Responsable recherche / Responsable développement",
    "Responsable bureau d’études",
    "Ingénieur chef de projet",
    "Dessinateur-projeteur / Dessinateur d’études",
    "Prototypiste",
    "Dessinateur",
    "Responsable de laboratoire",
    "Technicien de laboratoire",
    "Agent de laboratoire",

    "Chargé de recouvrement / Crédit manager",
    "Responsable service clients / service après-vente",
    "Responsable administration des ventes",
    "Chargé de relations clientèle",
    "Assistant administration des ventes",
    "Technicien d’installation et/ou de maintenance après-vente",

    "Directeur des ressources humaines",
    "Responsable ressources humaines",
    "Responsable administration du personnel et paie",
    "Responsable recrutement",
    "Responsable formation",
    "Assistant ressources humaines / Assistant paie",

    "Responsable des services généraux",
    "Hôte / Hôtesse d’accueil / Standardiste",
    "Agent des services généraux",

    "Autre"
]

export const getJobsSuggestions = async (label: string): Promise<AutosuggestOutput[]> => {
    return jobs.filter(job => job.toUpperCase().includes(label.toUpperCase()))
        .map((c, index) => ({
            id: c,
            label: c,
            value: c,
        }));
};


