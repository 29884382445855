interface ConfirmationProps {
    type: 'ERROR';
    title: string;
    text: string;
    handleConfimrmation: Function;
    handleCancel: Function;
}

export const ConfirmationPopup: React.FC<ConfirmationProps> = ({
    type, title, text, handleConfimrmation, handleCancel
}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className='bg-white text-gray-900 p-6 rounded-lg shadow-lg'>
                <h3 className="text-lg font-semibold mb-4">{title}</h3>
                <p>{text}</p>
                <div className="mt-4 flex justify-end space-x-2">
                    <button
                        onClick={() => handleCancel()}
                        className='px-4 py-2 bg-gray-200 text-gray-800 rounded'
                    >
                        Annuler
                    </button>
                    <button
                        onClick={() => handleConfimrmation()}
                        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                    >
                        Confirmer
                    </button>
                </div>
            </div>
        </div>
    );
};