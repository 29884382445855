import React, { useState, useEffect } from 'react';
import {
  Bell,
  Lock,
  User,
  Globe,
  Palette,
  Volume2,
  Mail,
  Shield,
  Languages,
  Clock,
  CreditCard,
  HelpCircle,
  ChevronRight,
  Check,
  Info,
  LucideIcon,
  ChevronLeft,
  Menu,
  X
} from 'lucide-react';
import { Switch } from './switch';

interface NotificationSettings {
  [key: string]: boolean;
}

interface SettingOption {
  id: string;
  label: string;
}

interface Subsection {
  id: string;
  title: string;
}

interface Section {
  id: string;
  title: string;
  icon: LucideIcon;
  options?: SettingOption[];
  subsections?: Subsection[];
}

const SettingsInterface = () => {
  const [activeSection, setActiveSection] = useState('profile');
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings>({
    emailNotifs: true,
    pushNotifs: true,
    soundEnabled: true,
    matchAlerts: true,
    messageNotifs: true,
    messageRequests: true,
    readReceipts: true,
    typing: true,
    darkMode: false,
    autoPlay: true
  });

  // Responsive states
  const [isMobile, setIsMobile] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth < 768;
      setIsMobile(isMobileView);
      setShowSidebar(!isMobileView);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sections: Section[] = [
    {
      id: 'profile',
      title: 'Profil',
      icon: User,
      subsections: [
        { id: 'professional', title: 'Parcours professionnel' },
        { id: 'skills', title: 'Compétences & Expertises' },
        { id: 'preferences', title: 'Préférences de matching' }
      ]
    },
    {
      id: 'notifications',
      title: 'Notifications',
      icon: Bell,
      options: [
        { id: 'emailNotifs', label: 'Notifications par email' },
        { id: 'pushNotifs', label: 'Notifications push' },
        { id: 'soundEnabled', label: 'Sons de notification' },
        { id: 'matchAlerts', label: 'Alertes de nouveaux matchs' },
        { id: 'messageNotifs', label: 'Notifications de messages' }
      ]
    },
    {
      id: 'privacy',
      title: 'Confidentialité & Sécurité',
      icon: Shield,
      subsections: [
        { id: 'visibility', title: 'Visibilité du profil' },
        { id: 'blockedUsers', title: 'Utilisateurs bloqués' },
        { id: 'dataPrivacy', title: 'Confidentialité des données' },
        { id: 'twoFactor', title: 'Authentification à deux facteurs' }
      ]
    },
    {
      id: 'communication',
      title: 'Communication',
      icon: Mail,
      options: [
        { id: 'messageRequests', label: 'Autoriser les demandes de message' },
        { id: 'readReceipts', label: "Accusés de lecture" },
        { id: 'typing', label: 'Indicateur de frappe' }
      ]
    },
    {
      id: 'appearance',
      title: 'Apparence',
      icon: Palette,
      options: [
        { id: 'darkMode', label: 'Mode sombre' },
        { id: 'fontSize', label: 'Taille de police' },
        { id: 'colorScheme', label: 'Thème de couleurs' }
      ]
    },
    {
      id: 'language',
      title: 'Langue & Région',
      icon: Globe,
      subsections: [
        { id: 'appLanguage', title: "Langue de l'application" },
        { id: 'timezone', title: 'Fuseau horaire' },
        { id: 'dateFormat', title: 'Format de date' }
      ]
    },
    {
      id: 'subscription',
      title: 'Abonnement & Paiement',
      icon: CreditCard,
      subsections: [
        { id: 'currentPlan', title: 'Plan actuel' },
        { id: 'billing', title: 'Historique de facturation' },
        { id: 'payment', title: 'Méthodes de paiement' }
      ]
    },
    {
      id: 'help',
      title: 'Aide & Support',
      icon: HelpCircle,
      subsections: [
        { id: 'faq', title: 'FAQ' },
        { id: 'contact', title: 'Contacter le support' },
        { id: 'tutorial', title: 'Tutoriels' }
      ]
    }
  ];

  const handleSettingChange = (optionId: string, value: boolean) => {
    setNotificationSettings(prev => ({
      ...prev,
      [optionId]: value
    }));
  };

  const handleSectionClick = (sectionId: string) => {
    setActiveSection(sectionId);
    if (isMobile) {
      setShowSidebar(false);
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="relative flex h-full bg-gray-50">
      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 bg-white z-20 px-4 py-3 flex items-center justify-between border-b">
        <button
          onClick={toggleSidebar}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          {showSidebar ? <X size={24} /> : <Menu size={24} />}
        </button>
        <h1 className="text-lg font-semibold">
          {showSidebar ? 'Paramètres' : sections.find(s => s.id === activeSection)?.title}
        </h1>
        <div className="w-8" />
      </div>

      {/* Settings Navigation */}
      <div
        className={`
          ${showSidebar ? 'translate-x-0' : '-translate-x-full'}
          ${isMobile ? 'fixed inset-y-0 left-0 z-10' : 'relative'}
          transform transition-transform duration-300 ease-in-out
          w-full md:w-64 md:min-w-[16rem]
          md:translate-x-0
          bg-white border-r
          overflow-y-auto
          pt-16 md:pt-4
          pb-6
        `}
      >
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-6 hidden md:block">Paramètres</h2>
          <nav className="space-y-1">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => handleSectionClick(section.id)}
                className={`w-full flex items-center space-x-3 px-3 py-2 rounded-lg transition-colors ${activeSection === section.id
                  ? 'bg-[#B3B4E5] text-[#333697]'
                  : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                <section.icon size={20} />
                <span>{section.title}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Content Area */}
      <div
        className={`
          flex-1 
          transition-all duration-300
          ${isMobile && showSidebar ? 'opacity-50' : 'opacity-100'}
          ${isMobile ? 'fixed inset-0 z-0' : 'relative'}
          overflow-y-auto
          pt-16 md:pt-4
          pb-6
        `}
      >
        <div className="p-4 md:p-6">
          {sections.map((section) => (
            <div
              key={section.id}
              className={activeSection === section.id ? 'block' : 'hidden'}
            >
              <div className="max-w-2xl mx-auto">
                {/* Section Header */}
                <div className="flex items-center mb-6">
                  {isMobile && !showSidebar && (
                    <button
                      onClick={() => setShowSidebar(true)}
                      className="mr-3 p-2 hover:bg-gray-100 rounded-lg md:hidden"
                    >
                      <ChevronLeft size={20} />
                    </button>
                  )}
                  <section.icon size={24} className="mr-3" />
                  <h1 className="text-xl md:text-2xl font-semibold">{section.title}</h1>
                </div>

                {/* Options-based sections */}
                {section.options && (
                  <div className="space-y-4 bg-white rounded-lg p-4 md:p-6 shadow-sm">
                    {section.options.map((option) => (
                      <div
                        key={option.id}
                        className="flex items-center justify-between py-2"
                      >
                        <span className="text-gray-700 text-sm md:text-base">{option.label}</span>
                        <Switch
                          checked={notificationSettings[option.id] || false}
                          onCheckedChange={(checked) => handleSettingChange(option.id, checked)}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {/* Subsections-based sections */}
                {section.subsections && (
                  <div className="space-y-3 md:space-y-4">
                    {section.subsections.map((subsection) => (
                      <div
                        key={subsection.id}
                        className="bg-white rounded-lg p-3 md:p-4 shadow-sm hover:bg-gray-50 cursor-pointer flex items-center justify-between"
                      >
                        <span className="text-gray-700 text-sm md:text-base">{subsection.title}</span>
                        <ChevronRight size={20} className="text-gray-400" />
                      </div>
                    ))}
                  </div>
                )}

                {/* Specific sections content */}
                {section.id === 'profile' && (
                  <div className="mt-4 text-xs md:text-sm text-gray-500 flex items-center">
                    <Info size={16} className="mr-2 flex-shrink-0" />
                    <span>Complétez votre profil pour augmenter vos chances de matching</span>
                  </div>
                )}

                {section.id === 'subscription' && (
                  <div className="mt-4 bg-blue-50 p-4 rounded-lg">
                    <p className="text-blue-800 flex items-center text-sm md:text-base">
                      <Info size={16} className="mr-2 flex-shrink-0" />
                      Version gratuite active
                    </p>
                    <button className="mt-2 bg-[#333697] text-white px-4 py-2 rounded-lg hover:bg-[#2a2d7a] text-sm md:text-base">
                      Passer à la version PRO
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile Overlay */}
      {isMobile && showSidebar && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-0"
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default SettingsInterface;