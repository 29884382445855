import { AxiosResponse } from "axios";
import { RestApi } from "../RestApi";
import { authProvider } from "../../AuthProvider";

export abstract class PrivateApi extends RestApi {
    constructor(baseUrl?: string) {
        super(baseUrl ?? process.env.REACT_APP_PRIVATE_API!);
    }

    protected async post(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        const token = await authProvider.getTokenDetails();

        path = path.replace("{id}", token!.userId);
        return super.post(path, data, {
            ...headers,
            'Authorization': token!.accessToken
        });
    }

    protected async put(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        const token = await authProvider.getTokenDetails();

        path = path.replace("{id}", token!.userId);
        return super.put(path, data, {
            ...headers,
            'Authorization': token!.accessToken
        });
    }

    protected async get(path: string, headers: any = {}): Promise<AxiosResponse> {
        const token = await authProvider.getTokenDetails();

        path = path.replace("{id}", token!.userId);
        return super.get(path, {
            ...headers,
            'Authorization': token!.accessToken
        });
    }

    protected async delete(path: string, headers: any = {}): Promise<AxiosResponse> {
        const token = await authProvider.getTokenDetails();

        path = path.replace("{id}", token!.userId);
        return super.delete(path, {
            ...headers,
            'Authorization': token!.accessToken
        });
    }
}
