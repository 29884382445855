import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { MatchingDto, MatchingRequestDto } from "../../../dto/Matching";

class MatchingApi extends PrivateApi {

    public async getMatching(matching: MatchingRequestDto): Promise<MatchingDto> {
        const path = `/matching/{id}`;
        const response: AxiosResponse = await super.post(path, matching);

        return response.data;
    }
}

export const matchingApi = new MatchingApi();