import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { ConversationDto, MessageByDayDto, MessageDto, NotificationDto, RequestDto, UserLinksDto } from "../../../dto/Contact";
import moment from "moment";

class ContactsApi extends PrivateApi {

    public async getCurrentUserLinks(): Promise<UserLinksDto> {
        const path = `/contacts/{id}`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async getConversations(): Promise<ConversationDto[]> {
        const path = `/messages/{id}`;
        const response: AxiosResponse = await super.get(path);
        const conversations = response.data.map((c: any) => ({ ...c, messages: this.toGroupedMessages(c.messages) }));
        return conversations;
    }

    public toGroupedMessages(messages?: MessageDto[]): MessageByDayDto[] {
        if (!messages) {
            return [];
        }

        const groupedByDay = messages.reduce((acc: MessageByDayDto[], msg: MessageDto) => {
            const day = moment(msg.sendDate).format('MMMM Do YYYY');

            let sendDate = moment(msg.sendDate).format('hh:mm');

            const dayMsgs = acc.find(a => a.day === day);

            if (dayMsgs) {
                dayMsgs.messages.push({ ...msg, sendDate });
            } else {
                acc.push({ day, messages: [{ ...msg, sendDate }] });
            }
            return acc;
        }, [] as MessageByDayDto[]);

        return groupedByDay;
    }

    public async sendMessage(userId: string, newMsg: MessageDto) {

        const path = `/messages/${userId}/new`;
        const response: AxiosResponse = await super.post(path, newMsg);

        return response.data;
    }

    public async createRoom(userId: string, userIds: string[]) {
        if (!userIds.find(id => id === userId)) {
            userIds.push(userId);
        }

        const path = `/messages/${userId}/room`;
        const response: AxiosResponse = await super.post(path, { userIds });

        return response.data;
    }

    public async setSeenStatus(userId: string, roomId: string, messageIds: number[]) {

        const path = `/messages/${userId}/room/${roomId}/seen`;
        const response: AxiosResponse = await super.post(path, messageIds);

        return response.data;
    }

    public async getRequestsSent(): Promise<RequestDto[]> {
        const path = `/contacts/{id}/requests/sent`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async getRequestsReceived(): Promise<RequestDto[]> {
        const path = `/contacts/{id}/requests/received`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async getConnectedUsers() {
        const path = `/contacts/{id}/connected`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async favorite(newFavoriteId: string) {
        const path = `/contacts/{id}/favorite`;
        const response: AxiosResponse = await super.post(path, { id: newFavoriteId });

        return response.data;
    }

    public async ignore(newIgnoredId: string) {
        const path = `/contacts/{id}/ignore`;
        const response: AxiosResponse = await super.post(path, { id: newIgnoredId });

        return response.data;
    }

    public async newContactRequest(receiverId: string) {
        const path = `/contacts/{id}/request`;
        const response: AxiosResponse = await super.post(path, { id: receiverId });

        return response.data;
    }

    public async acceptContactRequest(requesterId: string) {
        const path = `/contacts/{id}/accept`;
        const response: AxiosResponse = await super.post(path, { id: requesterId });

        return response.data;
    }

    public async rejectRequest(requesterId: string) {
        const path = `/contacts/{id}/reject/${requesterId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async deleteContact(contactId: string) {
        const path = `/contacts/{id}/${contactId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async deleteRequest(receiverId: string) {
        const path = `/contacts/{id}/request/${receiverId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async deleteFavorite(favoriteId: string) {
        const path = `/contacts/{id}/favorite/${favoriteId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async deleteIgnore(ignoredId: string) {
        const path = `/contacts/{id}/ignore/${ignoredId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async getNotifications(): Promise<NotificationDto[]> {
        const path = `/contacts/{id}/notifications`;
        const response: AxiosResponse = await super.get(path);

        const data: NotificationDto[] = response.data;
        return data.map(n => ({
            ...n,
            text: n.notificationType === "CONTACT_REQUEST" ? "Nouvelle invitation" : "Invitation acceptée"
        }));
    }

    public async getUnreadNotifications(): Promise<NotificationDto[]> {
        const path = `/contacts/{id}/notifications/unread`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async setNotificationsSeen(ids: string[]): Promise<NotificationDto[]> {
        const path = `/contacts/{id}/notifications/seen`;
        const response: AxiosResponse = await super.post(path, ids);

        return response.data;
    }
}

export const contactsApi = new ContactsApi();