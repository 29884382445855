import { AutosuggestOutput } from "../components/Autosuggest";

const fieldOfActivities = [

    "Agroalimentaire",
    "Banque/Assurance",
    "Bois/Papier/Carton/Imprimerie",
    "BTP/Matériaux de construction",
    "Chimie/Parachimie",
    "Commerce/Négoce/Distribution",
    "Édition/Communication/Multimédia",
    "Électronique/Électricité",
    "Études et conseils",
    "Industrie pharmaceutique",
    "Informatique/Télécoms",
    "Machines et équipements/Automobile",
    "Métallurgie/Travail du métal",
    "Plastique/Caoutchouc",
    "Services aux entreprises",
    "Textile/Habillement/Chaussure",
    "Transports/Logistique"
]

export const getFieldOfActivitiesSuggestions = async (label: string): Promise<AutosuggestOutput[]> => {
    return fieldOfActivities.filter(field => field.toUpperCase().includes(label.toUpperCase()))
        .map((c, index) => ({
            id: c,
            label: c,
            value: c,
        }));
};


