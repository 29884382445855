import { TokenDetails } from "../dto/TokenDetails";
import { authenticationApi } from "./api/public/AuthenticationApi";
import Cookies from "universal-cookie";

class AuthProvider {
    cookies = new Cookies();

    async signin(email: string, password: string, options: { rememberMe: boolean }) {
        const result = await authenticationApi.login(email, password);
        const now = Date.now();

        const expires = new Date(now + (options.rememberMe ? 30 * 24 * 60 * 60 * 1000 : 60 * 60 * 1000));
        this.cookies.set("pleeso", { ...result, rememberMe: options.rememberMe }, { path: "/", expires });
    }

    async signinGoogle(accessToken: string, options: { rememberMe: boolean }) {
        const result = await authenticationApi.loginGoogle(accessToken);
        const now = Date.now();

        const expires = new Date(now + (options.rememberMe ? 30 * 24 * 60 * 60 * 1000 : 60 * 60 * 1000));
        this.cookies.set("pleeso", { ...result, rememberMe: options.rememberMe }, { path: "/", expires });
    }

    async signout() {
        this.cookies.remove("pleeso");
    }

    async getTokenDetails(): Promise<TokenDetails | undefined> {

        let token = this.cookies.get("pleeso") as TokenDetails;

        const now = Date.now();
        if (token) {
            if (token.expireDate > now) {
                return token;
            }
            else if (!!token.rememberMe) {
                token = await authenticationApi.refreshToken(token.refreshToken)
                this.cookies.set("pleeso", { ...token, rememberMe: true }, { path: "/", expires: new Date(now + 30 * 24 * 60 * 60 * 1000) });
                return token;
            }
        }

        let params = new URLSearchParams();
        params.set("from", new URL(window.location.href).pathname);
        window.location.href = "/signin?" + params.toString();
    }
}

export const authProvider = new AuthProvider();