import { Check, ChevronLeft, Paperclip, Phone, Plus, Search, Send, Smile, Video, User } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import './Messages.css';
import { ContactDto, ConversationDto, MessageDto } from '../../dto/Contact';
import { contactsApi } from '../../service/api/private/ContactsApi';
import moment from 'moment';
import { filesApi } from '../../service/api/private/FilesApi';
import { useGlobalWebSocket } from '../App';
import { ReadyState } from 'react-use-websocket';
import { authProvider } from '../../service/AuthProvider';
import { useLocation } from 'react-router-dom';

export const Messages = () => {

    // Core states
    const [selectedConversation, setSelectedConversation] = useState<ConversationDto>();
    const [showContacts, setShowContacts] = useState<boolean>(false);
    const [newMessage, setNewMessage] = useState<string>('');
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const messageEndRef = useRef(null);
    // Responsive states
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isChatListVisible, setIsChatListVisible] = useState<boolean>(true);
    const [isConversationVisible, setIsConversationVisible] = useState<boolean>(false);

    const [userId, setUserId] = useState<string>();
    const [contacts, setContacts] = useState<ContactDto[]>([]);
    const [conversations, setConversations] = useState<ConversationDto[]>();

    const { lastMessage, sendMessage, readyState } = useGlobalWebSocket();
    const [wsReload, setWsReload] = useState(1);

    const [errors, setErrors] = useState({
        filesize: '',
        text: ''
    });

    const location = useLocation();

    useEffect(() => {
        authProvider.getTokenDetails()
            .then((result) => setUserId(result?.userId))
            .catch((error) => console.error(error))

        if (contacts.length === 0)
            contactsApi.getCurrentUserLinks()
                .then((result) => setContacts(result.contacts))
                .catch((error) => console.error(error))

        if (!conversations)
            contactsApi.getConversations()
                .then((result) => {
                    setConversations(result);
                    if (location.state && location.state.contact) {
                        let convToSet = result.find(c => c.userIds.includes(location.state.contact.id));
                        if (!convToSet) {
                            convToSet = { id: undefined, userIds: [location.state.contact.id], roomName: `${location.state.contact.firstName} ${location.state.contact.lastName}`, messages: [], unseenCount: 0 };
                            setConversations([...result ?? [],
                                convToSet
                            ]);
                        }

                        handleSelectConversation(convToSet);
                    }
                })
                .catch((error) => console.error(error))

        if (conversations && lastMessage !== null) {
            handleWsMessage(lastMessage.data)
        }

        const checkMobile = () => {
            const isMobileView = window.innerWidth < 1024;
            setIsMobile(isMobileView);
            if (isMobileView) {
                // setIsChatListVisible(true);
                //setIsConversationVisible(false);
            }
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, [lastMessage]);

    const handleWsMessage = async (data: any) => {
        const wsmsg = JSON.parse(data);
        if (wsmsg.action === 'sendmessage') {
            const newMsg: MessageDto = wsmsg.body;
            displayMessage(newMsg);
            await contactsApi.setSeenStatus(userId!, newMsg.roomId!, [newMsg.id!]);
        } else if (wsmsg.action === 'sendmessageseen') {
            seeMessage(wsmsg.body.roomId, wsmsg.body.messageIds);
        }
        setWsReload(p => p + 1);
    };

    const seeMessage = (roomId: string, messageIds: number[]) => {
        const conversation = conversations?.find(c => c.id === roomId);

        if (conversation) {
            conversation.messages.map(msg => msg.messages)
                .flat()
                .filter(msg => msg.senderId === userId && messageIds.includes(msg.id!))
                .forEach(msg => msg.seen = true);

            if (selectedConversation && selectedConversation.id === conversation.id) {
                setSelectedConversation(conversation);
            }

            setConversations(conversations);
        }
    };

    const handleSendMessage = async (content: string, messageType: 'TEXT' | 'FILE' = 'TEXT', fileName?: string) => {
        if (!content.trim()) return;

        const newMsg: MessageDto = {
            id: (selectedConversation?.lastMessage?.id ?? 0) + 1,
            senderId: userId!,
            content,
            messageType,
            fileName,
            roomId: selectedConversation?.id,
            sendDate: new Date().toISOString()
        };

        displayMessage(newMsg);

        setNewMessage('');
        setShowEmojiPicker(false);

        if (!selectedConversation?.id) {
            const roomId = await contactsApi.createRoom(userId!, selectedConversation?.userIds!);
            setSelectedConversation({ ...selectedConversation!, id: roomId });
            newMsg.roomId = roomId;
        }
        await contactsApi.sendMessage(userId!, newMsg);
    };

    const displayMessage = (newMsg: MessageDto) => {
        const conversation = conversations!.find(c => c.id === newMsg.roomId)!;
        const day = moment(newMsg.sendDate).format('MMMM Do YYYY');

        let sendDate = moment(newMsg.sendDate).format('hh:mm');

        const dayMsgs = conversation.messages.find(a => a.day === day);

        if (dayMsgs) {
            dayMsgs.messages.push({ ...newMsg, sendDate });
        } else {
            conversation.messages.push({ day, messages: [{ ...newMsg, sendDate }] });
        }

        conversation.lastMessage = newMsg;
        if (conversation.id === selectedConversation?.id) {
            setSelectedConversation(conversation);
        }
        setConversations(conversations);

        (messageEndRef.current as any)?.scrollIntoView({ behavior: 'smooth' });
        setWsReload(p => p + 1);
    }

    const handleCreateRoom = (contact: ContactDto) => {
        //Check if room exists
        const exists = conversations?.find(c => c.userIds.includes(contact.id));
        if (exists) {
            return;
        }
        setConversations([...conversations ?? [],
        { id: undefined, userIds: [contact.id, userId!], roomName: `${contact.firstName} ${contact.lastName}`, messages: [], unseenCount: 0 }
        ]);
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 15_000_000) {
                setErrors({ ...errors, filesize: 'La taille du fichier ne dois pas dépasser les 15 Mo.' })
                return;
            }

            setErrors({ ...errors, filesize: '' })

            const result = await filesApi.sendFile(selectedConversation?.userIds ?? [], file);
            handleSendMessage(result.uri, 'FILE', file.name);
        }
    };

    const handleMobileNavigation = (conversation: ConversationDto) => {
        handleSelectConversation(conversation);
        setIsChatListVisible(false);
        setIsConversationVisible(true);
    };

    const handleSelectConversation = async (conversation: ConversationDto) => {
        setSelectedConversation({ ...conversation, unseenCount: 0 });

        const messageIds = conversation.messages.map(msg => msg.messages)
            .flat()
            .filter(msg => !msg.seen && msg.senderId !== userId)
            .map(msg => msg.id!);

        if (messageIds && messageIds.length > 0) {
            await contactsApi.setSeenStatus(userId!, conversation.id!, messageIds);

            // Dispatch a custom event with some details
            const setUnreadMessages = new CustomEvent("setSeenMessages", {
                detail: conversation.unseenCount,
            });
            window.dispatchEvent(setUnreadMessages);

            const conv = conversations?.find(c => c.id === conversation.id);
            conv!.unseenCount = 0;
            setConversations(conversations);
        }
    };

    const handleBackToChatList = () => {
        setIsConversationVisible(false);
        setIsChatListVisible(true);
    };

    const addEmoji = (emoji: any) => {
        setNewMessage(prevMessage => prevMessage + emoji.emoji);
    };

    const downloadFile = async (message: MessageDto) => {
        const file = await filesApi.downloadFile(message.content);

        const linkSource = `data:${file.contentType};base64,${file.data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = file.name;
        downloadLink.click();
    }

    return (
        <>
            {/* Chat list */}
            <div
                className={`
            w-full lg:w-1/4 bg-white h-full
            ${(!isMobile || (isMobile && isChatListVisible && !isConversationVisible)) ? 'block' : 'hidden'}
            lg:block border-r
            pt-16 lg:pt-0
          `}
            >
                <div className="p-4">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold">Mes discussions</h2>
                        <button
                            className="bg-[#B3B4E5] text-blue-800 px-3 py-1 rounded text-sm flex items-center"
                            onClick={() => setShowContacts(!showContacts)}
                        >
                            <Plus size={16} className="mr-1" /> Créer
                        </button>
                    </div>

                    {showContacts && (
                        <div className="mt-2 border rounded p-2">
                            <h3 className="font-semibold mb-2">Contacts</h3>
                            {Boolean(wsReload) && contacts && contacts.map((contact) => (
                                <div key={contact.id} className="cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleCreateRoom(contact)}>
                                    {contact.firstName + ' ' + contact.lastName}
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="relative mt-4">
                        <input
                            type="text"
                            placeholder="Chercher un contact"
                            className="w-full p-2 pl-8 border rounded"
                        />
                        <Search className="absolute left-2 top-2 text-gray-400" size={20} />
                    </div>

                    <div className="mt-4 overflow-y-auto h-[calc(100vh-280px)]">
                        {!conversations &&
                            <div className="p-6">
                                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                            </div>
                        }
                        {conversations && conversations.map((conv) => (
                            <div
                                key={conv.id}
                                className={`p-4 cursor-pointer transition-colors duration-150
                    ${conv.id === selectedConversation?.id ? 'bg-[#B3B4E5]' : 'hover:bg-gray-100'}`}
                                onClick={() => {
                                    if (isMobile) {
                                        handleMobileNavigation(conv);
                                    } else {
                                        handleSelectConversation(conv);
                                    }
                                }}
                            >
                                <div className='flex'>
                                    <span className="text-base font-semibold">{conv.roomName}</span>
                                    {conv.unseenCount !== 0 &&
                                        <span className="ml-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                                            {conv.unseenCount}
                                        </span>
                                    }
                                </div>
                                {conv.lastMessage?.senderId !== userId ?
                                    conv.lastMessage?.messageType === 'FILE' ? (
                                        <a className="flex items-center">
                                            <Paperclip size={16} />
                                            {conv.lastMessage?.fileName}
                                        </a>
                                    ) : (
                                        <div className="text-sm text-gray-500">{conv.lastMessage?.content}</div>
                                    )
                                    : ''
                                }

                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Chat window */}
            <div
                className={`
            flex-1 flex flex-col h-full
            ${(!isMobile || (isMobile && isConversationVisible)) ? 'block' : 'hidden'}
            ${isMobile ? 'fixed inset-0 bg-white z-30' : ''}
          `}
            >
                {/* Chat header */}
                <div className="bg-white p-4 flex items-center justify-between border-b">
                    <div className="flex items-center">
                        {isMobile && (
                            <button
                                className="lg:hidden p-2 hover:bg-gray-100 rounded-full mr-2"
                                onClick={handleBackToChatList}
                            >
                                <ChevronLeft size={24} className="text-gray-600" />
                            </button>
                        )}
                        {selectedConversation && (<>
                            <User className="w-10 h-10 rounded-full mr-3" />
                            <div>
                                <div className="font-semibold">{selectedConversation?.roomName}</div>
                                <div className="text-sm text-green-500">En ligne</div>
                            </div>
                        </>)}
                    </div>
                    <div className="flex space-x-4">
                        <Phone className="text-gray-500 cursor-pointer hidden sm:block" size={20} />
                        <Video className="text-gray-500 cursor-pointer hidden sm:block" size={20} />
                        <Search className="text-gray-500 cursor-pointer" size={20} />
                    </div>
                </div>

                {/* Messages */}
                {Boolean(wsReload) && selectedConversation && <div className="flex-1 overflow-y-auto p-4 bg-[#B3B4E5] h-[85%]" id='messagesContainer'>
                    {selectedConversation.messages.map((messagesGrouped) => (
                        <div key={messagesGrouped.day}>
                            <div className="text-center text-xs text-gray-500 my-2">{messagesGrouped.day}</div>

                            {messagesGrouped.messages.map((message) => (
                                <div
                                    key={message.id}
                                    className={`mb-4 ${message.senderId === userId ? 'text-right' : ''}`}
                                >
                                    <div className="inline-block max-w-[70%]">
                                        <div className={`p-2 rounded-lg ${message.senderId === userId
                                            ? 'bg-[#333697] text-white'
                                            : 'bg-white'
                                            }`}>
                                            {message.messageType === 'FILE' ? (
                                                <a href="#" onClick={() => downloadFile(message)} className="flex items-center">
                                                    <Paperclip size={16} className="mr-2" />
                                                    {message.fileName}
                                                </a>
                                            ) : (
                                                <div className="break-words">{message.content}</div>
                                            )}
                                        </div>
                                        <div className="text-xs mt-1 flex justify-end items-center">
                                            <span className="mr-1">{message.sendDate}</span>
                                            {message.senderId === userId && (
                                                <Check size={16} className={message.seen ? 'text-blue-500' : 'text-gray-400'} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div ref={messageEndRef} />
                </div>}

                {/* Message input */}
                {selectedConversation && <div className="bg-white p-4 border-t">
                    {errors.filesize && (
                        <p className="mb-2 text-red-500 text-sm font-bold">{errors.filesize}</p>
                    )}
                    <div className="flex items-center space-x-2">
                        <button
                            className="text-gray-500 hover:text-gray-700 hidden sm:block"
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        >
                            <Smile size={24} />
                        </button>
                        <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <Paperclip size={24} />
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={handleFileUpload}
                        />
                        <div className="flex-1 relative">
                            <input
                                disabled={readyState !== ReadyState.OPEN}
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Écrivez votre message..."
                                className="w-full p-2 border rounded-l focus:outline-none focus:border-[#333697] focus:ring-1 focus:ring-[#333697]"
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(newMessage)}
                            />
                            {showEmojiPicker && (
                                <div className="absolute bottom-12 left-0 z-10 hidden sm:block">
                                    <EmojiPicker onEmojiClick={addEmoji} />
                                </div>
                            )}
                        </div>
                        <button
                            className="bg-[#333697] text-white p-2 rounded-r hover:bg-[#2a2d7a] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => handleSendMessage(newMessage)}
                            disabled={!newMessage.trim()}
                        >
                            <Send size={24} />
                        </button>
                    </div>
                </div>}
            </div>
        </>
    );
};