import './App.css';
import { createBrowserRouter, LoaderFunctionArgs, Outlet, redirect, RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import { authProvider } from '../service/AuthProvider';
import { Dashboard } from './dashboard/Dashboard';
import { LoginPage } from './signin/Signin';
import { Signup } from './signup/Signup'
import { Messages } from './messages/Messages';
import LandingPage, { landingLoader } from './landing/Landing';
import {
  Facebook, X, Instagram, Linkedin, ChevronDown, Menu, Check, Settings, LogOut,
  LayoutGrid,
  ArrowRightLeft,
  Users,
  MessageSquare,
  Bell,
} from 'lucide-react';
import Logo_Pleeso from '../assets/images/Logo_Pleeso.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { createContext, useContext, useEffect, useState } from 'react';
import { AccessButton, ButtonDestination } from '../components/AccessButon';
import WaitlistPage from './waitlist/Waitlist';
import ContactPage from './contact/Contact';
import { newContactApi } from '../service/api/public/NewContactApi';
import ActivatedPage from './activated/Activated';
import ResetPasswordPage from './resetpassword/ResetPassword';
import 'moment/locale/fr';
import SettingsInterface from './Settingsinterface';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Matching from './matching/Matching';
import Network from './network/Network';
import Notifications from './notifications/Notifications';
import { contactsApi } from '../service/api/private/ContactsApi';

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        loader: landingLoader,
        Component: LandingPage,
      },
      {
        path: "waitlist",
        Component: WaitlistPage
      },
      {
        path: "contact",
        Component: ContactPage
      },
      {
        path: "signin",
        Component: LoginPage,
      },
      {
        path: "signup/*",
        Component: Signup,
      },
      {
        path: "activated",
        Component: ActivatedPage
      },
      {
        path: "resetPassword",
        Component: ResetPasswordPage
      }
    ],
  },
  {
    id: "logged",
    path: "/dashboard",
    Component: MainLayout,
    children: [
      {
        index: true,
        loader: userLoader,
        Component: Dashboard,
      },
      {
        path: "messages",
        loader: userLoader,
        Component: Messages,
      },
      {
        path: "matching",
        loader: userLoader,
        Component: Matching,
      },
      {
        path: "network",
        loader: userLoader,
        Component: Network,
      },
      {
        path: "notifications",
        loader: userLoader,
        Component: Notifications,
      }
    ],
  },
]);

export async function userLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  const token = await authProvider.getTokenDetails();
  if (!token) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/signin?" + params.toString());
  }
  return null;
}

const solutionsItems = [
  'Trouver mon cofondateur',
  'Communication integrée',
  'Gestion des taches',
  'Pleeso optimisée par IA'
];

function Layout() {
  return (
    <>
      <Header />
      <main className="pt-16"> {/* Ajout d'un padding-top pour compenser le header fixe */}
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export interface WebSocketContextType {
  sendMessage: any;
  socketUrl: string;
  lastMessage: any;
  readyState: ReadyState;
}

// Create ws context
export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Custom hook
export const useGlobalWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useGlobalWebSocket must be used within WebSocketContext');
  }
  return context;
};

function MainLayout() {
  const [socketUrl, setSocketUrl] = useState('');

  useEffect(() => {
    authProvider.getTokenDetails()
      .then((result) => setSocketUrl(`${process.env.REACT_APP_WS_API!}?AuthToken=${result?.accessToken}`))
      .catch((error) => console.error(error))
  }, []);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const webSocket: WebSocketContextType = { sendMessage, socketUrl, lastMessage, readyState };

  return (
    <>
      <WebSocketContext.Provider value={webSocket}>
        <MainInterface />
      </WebSocketContext.Provider>
    </>
  );
}

const MainLinks: React.FC = () => {
  const location = useLocation();
  let links: ButtonDestination[] = ["home"];

  if (location.pathname === "/") {
    links = ["waitlist"];
  }

  return (<div className="flex items-center gap-4">
    {links.map(link => (
      <AccessButton key={link}
        destination={link}
        className="text-sm whitespace-nowrap"
        variant="primary"
      />
    ))}
  </div>
  )
}

///////////////////////////// COMPOSANT HEADER ///////////////////////
const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Mettre à jour hasScrolled basé sur la position de défilement
      setHasScrolled(currentScrollY > 0);

      if (currentScrollY <= 0) {
        setIsVisible(true);
      } else if (currentScrollY < lastScrollY) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY) {
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsSolutionsOpen(false);
  };

  const handleNavigateToHome = () => {
    navigate("/");
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 0 }}
        animate={{
          y: isVisible ? 0 : -100,
          opacity: isVisible ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
        className={`fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm
          ${hasScrolled ? 'border-b border-gray-200 shadow-sm' : ''} 
          transition-all duration-300`}
      >
        <header className="relative flex items-center justify-between w-full max-w-[1600px] mx-auto bg-white min-h-[56px] sm:min-h-[64px] px-5 lg:px-20 py-2">
          {/* Logo and Navigation */}
          <div className="flex items-center gap-3 sm:gap-4 md:gap-8 flex-grow md:flex-grow-0">
            <button onClick={handleNavigateToHome} className="flex gap-0 sm:gap-0.5 items-center whitespace-nowrap">
              <img
                src={Logo_Pleeso}
                alt="Pleeso Logo"
                className="w-10 sm:w-12 md:w-14 aspect-square"
              />
              <div className="text-[#333697] text-xl sm:text-2xl font-semibold">
                pleeso
              </div>
            </button>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex gap-4 lg:gap-8 items-center text-base lg:text-lg text-[#333697] font-semibold">
              <div className="relative group">
                <button className="group-hover:text-[#FC9723] flex items-center gap-1">
                  Solutions
                  <ChevronDown
                    size={20}
                    className="transform group-hover:rotate-180 transition-transform duration-200"
                  />
                </button>
                <div className="absolute top-full left-0 bg-white shadow-md rounded-md py-2 mt-1 z-10 hidden group-hover:block w-56">
                  {solutionsItems.map((item, index) => (
                    <a
                      key={index}
                      href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                      className="block px-4 py-2 hover:bg-[#FC9723] hover:text-white transition-colors duration-200 text-sm font-normal text-zinc-800"
                      onClick={handleLinkClick}
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
              {/* <Link
                to="/blog"
                className="hover:text-[#FC9723] transition-colors duration-200"
              >
                Blog
              </Link> */}
            </nav>
          </div>

          {/* Desktop Access Button */}
          {/* <div className="hidden md:block">
            <AccessButton destination="waitlist" className="min-h-[44px]" />
          </div> */}

          {/* Mobile Controls */}
          <div className="flex items-center gap-4">
            <MainLinks />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 text-[#333697] hover:bg-gray-100 rounded-md transition-colors duration-200 block md:hidden"
              aria-label="Menu"
            >
              <Menu size={24} />
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="absolute top-full left-0 right-0 bg-white shadow-lg z-50 md:hidden">
              <div className="flex flex-col py-2">
                <div className="border-b border-gray-100">
                  <button
                    className="flex items-center justify-between w-full px-4 py-2.5 text-[#333697] font-semibold hover:text-[#FC9723] transition-colors duration-200"
                    onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
                  >
                    Solutions
                    <ChevronDown
                      size={18}
                      className={`transform transition-transform duration-200 ${isSolutionsOpen ? 'rotate-180' : ''
                        }`}
                    />
                  </button>
                  <div className={`overflow-hidden transition-all duration-200 ${isSolutionsOpen ? 'max-h-48' : 'max-h-0'
                    }`}>
                    <div className="px-4 py-2 bg-gray-50">
                      {solutionsItems.map((item, index) => (
                        <a
                          key={index}
                          href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                          className="block py-2 text-sm text-zinc-800 hover:text-[#FC9723] transition-colors duration-200"
                          onClick={handleLinkClick}
                        >
                          {item}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <Link
                  to="/blog"
                  className="px-4 py-2.5 text-[#333697] font-semibold hover:text-[#FC9723] transition-colors duration-200"
                  onClick={handleLinkClick}
                >
                  Blog
                </Link> */}
              </div>
            </div>
          )}
        </header>
      </motion.div>
    </AnimatePresence>
  );
};

//////////////////////////////////////// FOOTER COMPONENT /////////////////////////
const Footer = () => {
  const [email, setEmail] = useState<string>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [errorCode, setErrorCode] = useState<string>('');

  const handleSubmitNewsletter = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (email) {
        setStatus("loading");
        setErrorCode('');
        await newContactApi.addToNewsletter(email);
        setStatus("success");
      }
    } catch (e: any) {
      setStatus("error");
      setErrorCode(e.message);
    }
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  return (
    <footer className="bg-gray-100 py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-10 gap-4 mb-8">
          {/* Logo et Description à l'extrême gauche */}
          <div className="col-span-12 md:col-span-2">
            <div className="flex items-center mb-4">
              <img loading="lazy" src={Logo_Pleeso} alt="Pleeso Logo" className="object-contain shrink-0 self-stretch my-auto w-12 rounded-full aspect-square" />
              <h3 className="text-2xl font-bold text-[#333697]">pleeso</h3>
            </div>
            <p className="text-base mb-4">Trouvez votre co-fondateur idéal et lancez votre startup avec succès.</p>
          </div>

          {/* Menu Items avec 4 colonnes */}
          <div className="col-span-12 md:col-span-5">
            <div className="grid grid-cols-2 gap-4">
              {/* Solutions */}
              <div>
                <h3 className="font-semibold text-[17px] mb-3">Solutions</h3>
                <ul className="text-[15px] space-y-2">
                  {solutionsItems.map((item, index) => (
                    <li key={index}><a
                      href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                      className="hover:text-[#FC9723]"
                    >
                      {item}
                    </a></li>
                  ))}
                </ul>
              </div>

              {/* Ressources */}
              {/* <div>
                <h3 className="font-semibold text-[17px] mb-3">Ressources</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="#" className="hover:text-[#FC9723]">Boîte à outils</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">E-learning</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Webinaires</a></li>
                </ul>
              </div> */}

              {/* Blog */}
              {/* <div>
                <h3 className="font-semibold text-[17px] mb-3">Blog</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="#" className="hover:text-[#FC9723]">Startup advice</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Success stories</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Tech trends</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Finance</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Updates</a></li>
                </ul>
              </div> */}

              {/* Contact */}
              <div>
                <h3 className="font-semibold text-[17px] mb-3">Nous contacter</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="mailto:contact@pleeso.com" className="hover:text-[#FC9723] pr-2">contact@pleeso.com</a></li>
                  {/* <li><a href="tel:+33123456789" className="hover:text-[#FC9723]">+33 1 23 45 67 89</a></li> */}
                </ul>
              </div>
            </div>
          </div>

          {/* Newsletter à l'extrême droite */}
          <div className="col-span-12 md:col-span-3">
            <h3 className="font-semibold text-[17px] mb-3">Newsletter</h3>
            <p className="text-[15px] mb-2">Restez informé de nos dernières actualités</p>
            {status === 'success' ? (
              <Check className="w-8 h-8 text-green-500" />
            ) : (
              <form onSubmit={handleSubmitNewsletter} className="flex flex-col max-w-full">
                {status === 'error' && errorCode === 'DUPLICATED_EMAIL' && (
                  <div className="block text-sm font-bold text-red-700 mb-1">
                    * Cette adresse e-mail est déjà dans la liste
                  </div>
                )}
                <input
                  type="email"
                  placeholder="Votre email"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-[#FC9723] mb-2.5 text-sm"
                  value={email}
                  onChange={handleEmailInputChange}
                />
                <button
                  type="submit"
                  disabled={status === 'loading'}
                  className="w-full bg-[#333697] text-white px-6 py-3 rounded-md hover:bg-[#FC9723] transitions-color transition duration-300 text-sm font-medium"
                >
                  {status === 'loading' ? (
                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <>
                      S'inscrire
                    </>
                  )}

                </button>
              </form>
            )}

          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center pt-6 border-t border-gray-300">
          <div className="text-[15px] text-base text-gray-600 order-2 lg:order-1 mt-6 lg:mt-6">
            © {new Date().getFullYear()} Pleeso. Tous droits réservés.
          </div>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/people/Pleeso/61552357269954/" className="text-gray-600 hover:text-[#FC9723]"><Facebook size={30} /></a>
            {/* <a href="#" className="text-gray-600 hover:text-[#FC9723]"><Twitter size={30} /></a> */}
            <a href="https://www.instagram.com/pleeso_project/" className="text-gray-600 hover:text-[#FC9723]"><Instagram size={30} /></a>
            <a href="https://www.linkedin.com/company/pleeso/" className="text-gray-600 hover:text-[#FC9723]"><Linkedin size={30} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

//////////////////////////////////////// MAIN COMPONENT /////////////////////////
const MainInterface: React.FC = () => {

  const location = useLocation();

  // Core states
  const [selectedMenu, setSelectedMenu] = useState<string>();
  const [showSettings, setShowSettings] = useState<boolean>(false);

  // Responsive states
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const [unreadMessages, setUnreadMessages] = useState<number>();
  const [unreadNotifications, setUnreadNotifications] = useState<number>();

  const navigate = useNavigate();
  const [previousWsMessage, setPreviousWsMessage] = useState<any>();
  const { lastMessage, sendMessage, readyState } = useGlobalWebSocket();
  const [wsReload, setWsReload] = useState(1);

  useEffect(() => {
    if (unreadMessages === undefined || !unreadNotifications === undefined) {
      contactsApi.getUnreadNotifications()
        .then((result) => {
          setUnreadMessages(result.filter(n => n.notificationType === 'MESSAGE_RECEIVED').length);
          setUnreadNotifications(result.filter(n => n.notificationType !== 'MESSAGE_RECEIVED').length);
        })
        .catch((error) => console.error(error));
    }

    window.addEventListener("setSeenMessages", setSeenMessages);
    window.addEventListener("setSeenNotification", setSeenMessages);

    if (lastMessage !== null && JSON.stringify(lastMessage.data) !== JSON.stringify(previousWsMessage)) {
      handleWsMessage(lastMessage.data)
      setPreviousWsMessage(lastMessage.data);
    }

    setSelectedMenu(location.pathname.toLowerCase())
    const checkMobile = () => {
      const isMobileView = window.innerWidth < 1024;
      setIsMobile(isMobileView);
      if (isMobileView) {
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener("setSeenMessages", setSeenMessages);
      window.removeEventListener("setSeenNotification", setSeenMessages);
    };
  }, [location, lastMessage]);

  const handleWsMessage = async (data: any) => {
    const wsmsg = JSON.parse(data);

    if (wsmsg.action === 'sendmessage') {
      setUnreadMessages((unreadMessages ?? 0) + 1);
    } else if (wsmsg.action === 'sendnotification') {
      setUnreadNotifications((unreadNotifications ?? 0) + 1);
    }
    setWsReload(p => p + 1);
  };

  const setSeenMessages = (e: any) => {
    contactsApi.getUnreadNotifications()
      .then((result) => {
        setUnreadMessages(result.filter(n => n.notificationType === 'MESSAGE_RECEIVED').length);
        setUnreadNotifications(result.filter(n => n.notificationType !== 'MESSAGE_RECEIVED').length);
      })
      .catch((error) => console.error(error));
  };

  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  const handleLogoutClick = async () => {
    await authProvider.signout();
    navigate("/");
  };

  const handleMenuClick = (path: string) => {
    if (selectedMenu !== path) {
      setSelectedMenu(path);
      navigate(path);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 relative overflow-hidden">
      {/* Mobile Menu Button */}
      <button
        className="lg:hidden fixed top-4 right-4 z-50 p-2 bg-white rounded-full shadow-lg"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Sidebar */}
      <div
        className={`
          fixed lg:relative lg:flex
          w-[80vw] lg:w-[7cm] h-full
          bg-white z-40
          transform transition-transform duration-300 ease-in-out
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          lg:translate-x-0
          flex flex-col
          border-r border-gray-200
          shadow-lg lg:shadow-none
          overflow-y-auto
          pt-16 lg:pt-4 px-4
        `}
      >
        <div className="flex items-center mb-8">
          <img src={Logo_Pleeso} alt="Logo Pleeso" className="h-12 mr-0" />
          <div className="text-2xl font-bold text-[#333697]">Pleeso</div>
        </div>

        <nav className="space-y-2">
          <div
            key='/dashboard'
            className={`p-2 rounded flex items-center cursor-pointer
                ${selectedMenu === '/dashboard' ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
            onClick={() => {
              handleMenuClick('/dashboard');
              setShowSettings(false);
            }}
          >
            <LayoutGrid className="mr-3" size={20} />
            <span className="text-base font-medium">Dashboard</span>
          </div>

          <div
            key='/dashboard/matching'
            className={`p-2 rounded flex items-center cursor-pointer
                ${selectedMenu === '/dashboard/matching' ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
            onClick={() => {
              handleMenuClick('/dashboard/matching');
              setShowSettings(false);
            }}
          >
            <ArrowRightLeft className="mr-3" size={20} />
            <span className="text-base font-medium">Matching</span>
          </div>

          <div
            key='/dashboard/network'
            className={`p-2 rounded flex items-center cursor-pointer
                ${selectedMenu === '/dashboard/network' ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
            onClick={() => {
              handleMenuClick('/dashboard/network');
              setShowSettings(false);
            }}
          >
            <Users className="mr-3" size={20} />
            <span className="text-base font-medium">Réseau</span>
          </div>

          <div
            key='/dashboard/messages'
            className={`p-2 rounded flex items-center cursor-pointer
                ${selectedMenu === '/dashboard/messages' ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
            onClick={() => {
              handleMenuClick('/dashboard/messages');
              setShowSettings(false);
            }}
          >
            <MessageSquare className="mr-3" size={20} />
            <span className="text-base font-medium">Chat</span>
            {Boolean(wsReload) && unreadMessages !== 0 &&
              <span className="ml-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                {unreadMessages}
              </span>
            }
          </div>

          <div
            key='/dashboard/notifications'
            className={`p-2 rounded flex items-center cursor-pointer
                ${selectedMenu === '/dashboard/notifications' ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
            onClick={() => {
              handleMenuClick('/dashboard/notifications');
              setShowSettings(false);
            }}
          >
            <Bell className="mr-3" size={20} />
            <span className="text-base font-medium">Notifications</span>
            {Boolean(wsReload) && unreadNotifications !== 0 &&
              <span className="ml-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                {unreadNotifications}
              </span>
            }
          </div>
        </nav>


        {/* Section Paramètres et Déconnexion - Hauteur fixe */}
        <div className="flex-shrink-0 px-4 py-4  mt-auto">
          <div
            className={`p-2 rounded flex items-center cursor-pointer mb-2 ${showSettings ? 'bg-[#B3B4E5] text-blue-800' : 'text-gray-700 hover:bg-gray-100'
              }`}
            onClick={handleSettingsClick}
          >
            <Settings className="mr-3" size={20} />
            <span className="text-base font-medium">Paramètres</span>
          </div>
          <div
            className="p-2 text-gray-700 hover:bg-red-100 hover:text-red-600 rounded flex items-center cursor-pointer"
            onClick={handleLogoutClick}
          >
            <LogOut className="mr-3" size={20} />
            <span className="text-base font-medium">Déconnexion</span>
          </div>
        </div>
      </div>

      {/* Modal des paramètres */}
      {showSettings && (
        <div className="fixed inset-0 z-50">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={handleSettingsClick} />
          <div className="absolute inset-4 md:inset-10 bg-gray-50 rounded-lg shadow-xl overflow-hidden">
            <SettingsInterface />
            <button
              className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200"
              onClick={handleSettingsClick}
            >
              <span className="sr-only">Fermer</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Chat modal */}
      {/*true && (
        <MessagePopup handleClose={() => console.log()} />
      )*/}

      {/* Main Content Container */}
      <div className="flex-1 flex flex-col lg:flex-row w-full">
        <Outlet />
      </div>

      {/* Mobile menu overlay */}
      {isMobileMenuOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
};

function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
