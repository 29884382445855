import { useEffect, useState } from "react";
import { UserDto } from "../dto/User";
import { User } from "lucide-react";


interface UserWidgetProps {
    user?: UserDto;
    children?: any;
}

export const UserWidget: React.FC<UserWidgetProps> = ({ user, children }) => {
    const [userIcon, setUserIcon] = useState<string>();

    useEffect(() => {
        if (user && user.image) {
            //`data:${result.contentType};base64,${result.data}`
            setUserIcon(user.image);
        }
    }, [user]);

    return <div className="bg-white p-4 md:p-6 rounded-lg shadow">

        {user ?
            <>
                <div className="flex items-center space-x-3 md:space-x-4 mb-4">
                    {userIcon ?
                        <img src={userIcon} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                        :
                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                    }
                    <div>
                        <h2 className="font-bold text-base md:text-lg">{user?.firstName} {user?.lastName}</h2>
                        <p className="text-xs md:text-sm text-gray-600">{user?.job}</p>
                        <span className="text-green-500 text-xs md:text-sm">● Disponible</span>
                    </div>
                </div>
                {children}
            </>
            :
            <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
            </div>
        }

    </div>
};