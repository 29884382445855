import { useState } from 'react';
import { Eye, EyeOff, User, Key, Loader2, ArrowLeft, Mail } from 'lucide-react';
import { Alert, AlertDescription } from '../../components/Alert';
import { authProvider } from '../../service/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { authenticationApi } from '../../service/api/public/AuthenticationApi';

interface LoginError {
  message: string;
  field?: string;
}

const PasswordReset = ({ onBack }: { onBack: () => void }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await authenticationApi.sendMailresetPassword(email);
      setSuccess(true);
    } catch (err) {
      setError("Erreur lors de l'envoi de l'email de réinitialisation");
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <div className="space-y-4">
        <div className="text-center">
          <h2 className="text-xl font-semibold">Email envoyé!</h2>
          <p className="mt-2 text-gray-600">
            Vérifiez votre boîte mail pour réinitialiser votre mot de passe.
          </p>
        </div>
        <button
          onClick={onBack}
          className="w-full flex items-center justify-center text-blue-900 hover:underline"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Retour à la connexion
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <button
        onClick={onBack}
        className="flex items-center text-blue-900 hover:underline"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Retour
      </button>

      <h2 className="text-xl font-semibold">Réinitialiser le mot de passe</h2>

      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="nom@exemple.com"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : (
            'Envoyer le lien'
          )}
        </button>
      </form>
    </div>
  );
};

export const LoginPage = () => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<LoginError | null>(null);
  const navigate = useNavigate();

  const params = new URLSearchParams(useLocation().search);
  const from = params.get("from") || "/dashboard";

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async tokenResponse => await handlOAuthSuccess(tokenResponse),
  //   onError: () => handlOAuthError()
  // });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (!email.includes('@')) {
        setError({ message: 'Email invalide', field: 'email' });
      } else if (password.length < 8) {
        setError({ message: 'Le mot de passe doit contenir au moins 8 caractères', field: 'password' });
      } else {
        await authProvider.signin(email, password, { rememberMe });
        navigate(from);
      }
    } catch (err) {
      setError({ message: "L'addresse email ou le mot de passe est incorrect. Réessayez." });
    } finally {
      setIsLoading(false);
    }
  };

  const handlOAuthSuccess = async (credentialResponse: any) => {
    setIsLoading(true);
    setError(null);
    console.log(credentialResponse);
    try {
      await authProvider.signinGoogle(credentialResponse.credential, { rememberMe });
      navigate(from);
    } catch (err) {
      setError({ message: "L'addresse email inconnue." });
    } finally {
      setIsLoading(false);
    }
  };

  const handlOAuthError = () => {
    setError({ message: "Erreur d'authentification." });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-md w-full space-y-6 bg-white rounded-lg shadow-lg border border-blue-900 p-4 sm:p-8">
        {showPasswordReset ? (
          <PasswordReset onBack={() => setShowPasswordReset(false)} />
        ) : (
          <>
            <div className="flex justify-center">
              <div className="text-3xl font-bold text-blue-900">Connexion</div>
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`block w-full pl-10 pr-3 py-2 border ${error?.field === 'email' ? 'border-red-500' : 'border-gray-300'
                      } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Key className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`block w-full pl-10 pr-10 py-2 border ${error?.field === 'password' ? 'border-red-500' : 'border-gray-300'
                      } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                    placeholder="Mot de passe"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="h-4 w-4 text-blue-600 rounded border-gray-300"
                  />
                  <label htmlFor="remember-me" className="ml-2 text-sm text-gray-900">
                    Se souvenir de moi
                  </label>
                </div>
                <button
                  type="button"
                  onClick={() => setShowPasswordReset(true)}
                  className="text-sm text-blue-900 hover:underline"
                >
                  Mot de passe oublié ?
                </button>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  'SE CONNECTER'
                )}
              </button>
            </form>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Ou continuer avec</span>
              </div>
            </div>

            <div>
              <GoogleOAuthProvider clientId="55648231277-qjleia2i93qr50gbcdrva61vrl7d3ki2.apps.googleusercontent.com">
                <GoogleLogin size='large' width="350px"
                  onSuccess={handlOAuthSuccess}
                  onError={handlOAuthError}
                />
              </GoogleOAuthProvider>
            </div>

            <div className="text-center text-sm">
              <span className="text-gray-600">Pas encore de compte ? </span>
              <a href="/signup" className="text-blue-900 hover:underline font-medium">
                Rejoignez l'aventure Pleeso!
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};