import React, { useState, useEffect } from 'react';
import { User } from 'lucide-react';
import { contactsApi } from '../../service/api/private/ContactsApi';
import { NotificationDto } from '../../dto/Contact';

const Notifications: React.FC = () => {
    const [notifications, setNotifications] = useState<NotificationDto[]>([]);
    const [unreadNotifications, setUnreadNotifications] = useState<NotificationDto[]>([]);
    const [currentView, setCurrentView] = useState<'TOUT' | 'NON_LU'>('NON_LU');
    const isDarkMode = false;

    useEffect(() => {
        if (notifications.length === 0) {
            contactsApi.getNotifications()
                .then((result) => {
                    setNotifications(result);
                    const unseen = result.filter(n => !n.seen);

                    if (unseen && unseen.length > 0) {
                        setUnreadNotifications(unseen);
                        contactsApi.setNotificationsSeen(unseen.map(n => n.id))
                            .then((result) => { // Dispatch a custom event with some details
                                const setUnreadMessages = new CustomEvent("setSeenNotification", {
                                    detail: unseen.length,
                                });
                                window.dispatchEvent(setUnreadMessages);
                            })
                            .catch((error) => console.error(error));
                    }
                })
                .catch((error) => console.error(error));
        }
    }, [currentView]);

    const handleChangeView = (view: 'TOUT' | 'NON_LU') => {
        setCurrentView(view);
    }

    return (
        <>
            <div className="flex-1 overflow-auto">
                <div className="container mx-auto px-4 py-8">
                    <div className="flex space-x-1 md:space-x-6 mb-6">
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'NON_LU' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('NON_LU')}>
                            Non lu
                        </button>
                        <button className={`px-2 py-1 rounded-2xl font-medium ${currentView === 'TOUT' ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'border border-indigo-600 text-indigo-600 hover:bg-indigo-100'}`}
                            onClick={async () => await handleChangeView('TOUT')}>
                            Tout
                        </button>
                    </div>

                    {/* NON_LU cards */}
                    <div className="space-y-4">
                        {currentView === 'NON_LU' && !notifications &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'NON_LU' && unreadNotifications && unreadNotifications.map((notification) => (
                            <div key={notification.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {notification.sender.image ?
                                        <img src={notification.sender.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {notification.sender.firstName + " " + notification.sender.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{notification.sender.address?.label}</span>
                                        </div>
                                    </div>
                                    <span>{notification.text}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* TOUT cards */}
                    <div className="space-y-4">
                        {currentView === 'TOUT' && !notifications &&
                            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    <div>
                                        <span className={`text-lg font-semibold animate-pulse leading-relaxed bg-gray-400 ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {[...Array(40)].map(i => (<>&nbsp;</>))}</span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium animate-pulse bg-gray-400">{[...Array(80)].map(i => (<>&nbsp;</>))}</span>
                                        </div>
                                    </div>                                 <div>
                                        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {currentView === 'TOUT' && notifications && notifications.map((notification) => (
                            <div key={notification.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex justify-between items-center`}>
                                <div className="flex items-center space-x-4">
                                    {notification.sender.image ?
                                        <img src={notification.sender.image} alt="User avatar" className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                        :
                                        <User className="w-12 h-12 md:w-16 md:h-16 rounded-full" />
                                    }
                                    <div>
                                        <span className={`text-lg font-semibold ${isDarkMode ? 'text-white hover:text-indigo-400' : 'text-gray-900'}`}>
                                            {notification.sender.firstName + " " + notification.sender.lastName.toUpperCase()}
                                        </span>
                                        <div className="flex items-center mt-1">
                                            <svg className="w-4 h-4 text-gray-500 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                                            <span className="text-xs text-gray-600 font-medium">{notification.sender.address?.label}</span>
                                        </div>
                                    </div>
                                    <span>{notification.text}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>

    );
};

export default Notifications;