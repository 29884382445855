import React, { useEffect, useState } from 'react';
import { Briefcase, Globe2, Languages, Coffee, BookOpen } from 'lucide-react';
import { UserWidget } from '../../components/UserWidget';
import { UserDto } from '../../dto/User';
import { Tag } from '../../components/Tag';
import { matchingApi } from '../../service/api/private/MatchingApi';
import { MatchingDto } from '../../dto/Matching';
import { contactsApi } from '../../service/api/private/ContactsApi';

import './Matching.css';

const PAGE_SIZE = 30;
const Matching = () => {

  const [matching, setMatching] = useState<MatchingDto>();
  const [currentMatch, setCurrentMatch] = useState<UserDto>();
  const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
  const [currentMatchPage, setCurrentMatchPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [nomoreMatch, setNomoreMatch] = useState(false);
  const [favoriteUsers, setFavoriteUsers] = useState<string[]>([]);

  useEffect(() => {
    if (favoriteUsers.length === 0) {
      contactsApi.getCurrentUserLinks()
        .then((result) => (setFavoriteUsers(result.favoriteUsers.map(f => f.id))));
    }
    if (!currentMatch) {
      matchingApi.getMatching({ size: PAGE_SIZE, page: currentMatchPage })
        .then((result) => {
          setMatching(result);
          if (result.content && result.content.length > 0) {
            setCurrentMatch(result.content[0].content);
          } else {
            setNomoreMatch(true);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [currentMatch, currentMatchPage]);

  const handleRequestContact = async () => {
    setLoading(true);

    await contactsApi.newContactRequest(currentMatch!.id!);
    const newMatch = await goNextMatch();
    if (newMatch) {
      setLoading(false);
    } else {
      //setNomoreMatch(true);
    }
  };

  const handleFavorite = async () => {
    setLoading(true);

    await contactsApi.favorite(currentMatch!.id!);
    const newMatch = await goNextMatch();
    if (newMatch) {
      setLoading(false);
    } else {
      //setNomoreMatch(true);
    }
  };

  const handleIgnore = async () => {
    setLoading(true);

    await contactsApi.ignore(currentMatch!.id!);
    const newMatch = await goNextMatch();

    if (newMatch) {
      setLoading(false);
    } else {
      //setNomoreMatch(true);
    }

  };

  const isAFavorite = () => {
    return favoriteUsers.includes(currentMatch?.id!);
  }

  const goNextMatch = async () => {
    const nextIndex = currentMatchIndex + 1;
    const hasNextInPage = matching!.numberOfElements > nextIndex;

    if (hasNextInPage) {
      setCurrentMatch(matching!.content[nextIndex].content);
      setCurrentMatchIndex(nextIndex);
    } else if (!matching!.last) {
      const newMatchingPage = await matchingApi.getMatching({ size: PAGE_SIZE, page: currentMatchPage + 1 });
      setCurrentMatchPage(currentMatchPage + 1);
      setMatching(newMatchingPage)

      setCurrentMatch(matching!.content[0].content);
      setCurrentMatchIndex(0);
    } else {
      console.log("last match !");
      setCurrentMatch(undefined)
      return false;
    }

    return true;
  }

  return (
    <>
      {nomoreMatch && <div className='overlay font-bold'>Aucun utilisateur ne correspond à vos critères, veuillez affiner votre sélection</div>}
      <div className="flex-1 px-4 py-4 md:p-6 lg:p-8 overflow-y-auto h-[100%]">
        {/* Dashboard Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 lg:gap-6">
          {/* User Profile */}
          <UserWidget user={currentMatch} >
            <div className="flex gap-2 mt-3">
              <button className={`px-4 py-2 text-sm text-white rounded-md font-medium ${loading ? 'bg-gray-300 cursor-not-allowed' : 'bg-emerald-500 border-2 border-emerald-500 hover:bg-emerald-600'}`}
                onClick={handleRequestContact}
                disabled={loading}>
                Inviter
              </button>
              <button className={`px-4 py-2 text-sm text-white rounded-md font-medium ${loading || isAFavorite() ? 'bg-gray-300 cursor-not-allowed' : 'bg-orange-500 border-2 border-orange-500 hover:bg-orange-600'}`}
                onClick={handleFavorite}
                disabled={loading || isAFavorite()}>
                Sauvegarder
              </button>
              <button className={`px-4 py-2 text-sm text-white rounded-md font-medium ${loading || isAFavorite() ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-500 border-2 border-red-500 hover:bg-red-600'}`}
                onClick={handleIgnore}
                disabled={loading}>
                Ignorer
              </button>
            </div>
          </UserWidget>

          {/* description */}
          <div className="bg-white p-4 md:p-6 rounded-lg shadow">
            <h2 className="font-bold text-base md:text-lg mb-2 flex items-center">
              <BookOpen className="mr-2 text-[#333697]" size={20} />
              Description :
            </h2>
            {currentMatch ?
              <p className="text-xs md:text-sm text-gray-600">
                {currentMatch?.description}
              </p>
              :
              <div>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
              </div>
            }

          </div>

          {/* informations perso */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6">
              <h3 className="font-medium text-lg mb-4">Informations personnelles</h3>
              {currentMatch ?
                <div className="space-y-4">
                  <div className="flex items-center text-sm">
                    <Briefcase className="text-[#333697] w-5 h-5 mr-3" />
                    <div>
                      <span className="text-gray-500">Expériences :</span>
                      <span className="ml-2 font-medium">{currentMatch?.yearsOfExperience} an(s)</span>
                    </div>
                  </div>
                  <div className="flex items-center text-sm">
                    <Globe2 className="text-[#333697] w-5 h-5 mr-3" />
                    <div>
                      <span className="text-gray-500">Localisation :</span>
                      <span className="ml-2 font-medium">{currentMatch?.address.label}</span>
                    </div>
                  </div>
                  <div className="flex items-start text-sm">
                    <Languages className="text-[#333697] w-5 h-5 mr-3 mt-1" />
                    <div>
                      <span className="text-gray-500">Langues :</span>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {currentMatch?.languages && currentMatch?.languages.map((lang, idx) => (
                          <Tag key={idx} text={lang} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start text-sm">
                    <Coffee className="text-[#333697] w-5 h-5 mr-3 mt-1" />
                    <div>
                      <span className="text-gray-500">Hobbies :</span>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {currentMatch?.hobbies && currentMatch?.hobbies.map((hobby, idx) => (
                          <Tag key={idx} text={hobby} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                :
                <>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                </>
              }
            </div>
          </div>

          {/* Experience Block */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-lg">Dernières expériences</h3>
                <button className="text-[#333697] text-sm hover:underline">Tout voir</button>
              </div>

              {currentMatch ?
                <div className="grid grid-cols-3 gap-4">
                  {currentMatch?.experiences && currentMatch?.experiences.map((experience, idx) => (
                    <div key={idx} className="p-4 rounded-lg border-2 border-[#B3B4E5] bg-white">
                      <h4 className="font-medium text-sm">{experience.title}</h4>
                      <p className="text-xs text-gray-500 mt-1">chez {experience.company}</p>
                      <div className="flex flex-col mt-2 text-xs text-gray-500">
                        <span>{`${experience.from} - ${experience.to}`}</span>
                        <span>{experience.address.label}</span>
                      </div>
                    </div>
                  ))}
                </div>
                :
                <>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                </>
              }
            </div>
          </div>

          {/* Skills Block */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6">
              <h3 className="font-medium text-lg mb-4">Compétences principales</h3>
              {currentMatch ?
                <div className="flex flex-wrap gap-2">
                  {currentMatch?.skills && currentMatch?.skills.map((skill, idx) => (
                    <Tag key={idx} text={skill} />
                  ))}
                </div>
                :
                <>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                </>
              }
            </div>
          </div>

          {/* Projects Block */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-lg">Projets</h3>
                <button className="text-[#333697] text-sm hover:underline">Tout voir</button>
              </div>
              {currentMatch ?
                <div className="grid grid-cols-3 gap-4">
                  {currentMatch?.projects && currentMatch.projects.map((project, idx) => (
                    <div key={idx} className="p-4 rounded-lg border-2 border-[#B3B4E5]">
                      <div className="w-10 h-10 border-2 border-[#B3B4E5] rounded-lg flex items-center justify-center mb-3">
                        <Briefcase className="text-[#333697]" size={20} />
                      </div>
                      <h4 className="font-medium text-sm">{project.name}</h4>
                      <p className="text-xs text-gray-500 mt-1">{`${Math.ceil(project.durationInMonth / 12)} year(s)`}</p>
                    </div>
                  ))}
                </div>
                :
                <>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matching;
