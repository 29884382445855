const hobbies = [
    "Sport", "Musique",
    "Lecture", "Voyages",
    "Cinéma", "Cuisine",
    "Art", "Jardinage",
    "Danse", "Photographie",
    "Yoga", "Méditation",
    "Nutrition", "Technologie"
]

export const getHobbiesSuggestions = hobbies.map((c, index) => ({
    id: c,
    value: c,
}));


